import { render, staticRenderFns } from "./relateFriend.vue?vue&type=template&id=048911fa&scoped=true&"
import script from "./relateFriend.vue?vue&type=script&lang=js&"
export * from "./relateFriend.vue?vue&type=script&lang=js&"
import style0 from "./relateFriend.vue?vue&type=style&index=0&id=048911fa&lang=less&scoped=true&"
import style1 from "./relateFriend.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048911fa",
  null
  
)

export default component.exports