<template>
  <div class="keyUser">
    <div class="keyUser-list">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <wxbNoData v-show="noStore"  ></wxbNoData>
        <van-list :finished="finished" :finished-text="dataList.length > 5 ? '没有更多了' : ''" @load="getKeyUserList">
          <WxbUserCard v-for="item of dataList" :key="item.clueId" :userInfo="item" @otherPerson="otherPerson"></WxbUserCard>
        </van-list>
      </van-pull-refresh>
    </div>
    <!--相关好友弹窗 S-->
    <relate-friend :showFriend.sync="showFriend" :clueId="clueId"/>
    <!--相关好友弹窗 E-->
  </div>
</template>

<script>
  import clue from '../../api/clue';
  import RelateFriend from './components/relateFriend';
  import WxbUserCard from '../../components/global/wxbUserCard';

  export default {
    name: 'keyUser',
    components: {
      RelateFriend,
      WxbUserCard
    },
    data() {
      return {
        pageNo: 1,
        pageSize: 20,
        clueId: '',
        noStore: false,
        finished: false,
        loading: false,
        dataList: [],
        showFriend: false
      };
    },
    methods: {
      async getKeyUserList() {
        try {
          this.loading = true;
          let query = {
            pageNo: this.pageNo,
            pageSize: this.pageSize
          };
          const { data, code, msg} = await clue.getKeyUserList(query);
          if (code !== '0') {
            this.finished = true;
            this.$toast('接口调用失败');
            console.error('接口报错', msg);
            return;
          }
          this.loading = false;
          if (this.pageNo === data.totalPages || data.totalPages===0) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          if (this.pageNo === 1) {
            this.dataList = data.data;
          } else {
            this.dataList = this.dataList.concat(data.data);
          }
          if (data.totalRecords == 0) {
            this.noStore = true;
          } else {
            this.noStore = false;
          }
          this.totalRecords = data.totalRecords;
          this.pageNo++;
        } catch (err) {
          this.loading = false;
          console.error('client sea error', err);
        }
      },
      onRefresh() {
        this.pageNo = 1;
        this.getKeyUserList();
      },
      otherPerson(res) {
        this.showFriend = true;
        this.clueId = res.clueId;
      }
    }
  };
</script>

<style lang="less" scoped>
.keyUser{
  .keyUser-list{
    padding: 12px;
    .wxb-userCard{
      margin-bottom: 12px;
    }
  }
  .van-pull-refresh {
    min-height: 500px;
    .van-list{
      padding-bottom: calc(20px + env(safe-area-inset-bottom));
    }
  }
}
</style>
