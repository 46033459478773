<template>
  <van-popup
    :safe-area-inset-bottom="true"
    @close="close"
    v-model="showDialog"
    round
    position="bottom"
    class="tag-popup"
  >
    <div class="tag-popup-title">
      <div @click="cancal" class="reset-title">取消</div>
      <div class="center-title">相关用户</div>
      <svg @click="prompt" class="icon-arrow" aria-hidden="true">
        <use xlink:href="#icontishi_you"></use>
      </svg>
    </div>
    <div class="tag-popup-content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="friendList.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="100"
        >
          <div
            @click="goDetail(item.clueId)"
            class="friend-list"
            v-for="(item, index) of friendList"
            :key="index"
          >
            <div class="friend-info">
              <div class="friend-content">
                <img :src="item.avatar" alt="" :onerror="defaultImg" />
                <div class="friend-name-label">
                  <div class="friend-name-info">
                    <span>{{ item.nickName }}</span>
                    <svg
                      v-if="item.hasFocusQw"
                      class="icon-arrow"
                      aria-hidden="true"
                    >
                      <use xlink:href="#iconqiyeweixin"></use>
                    </svg>
                    <svg
                      v-if="item.hasFocusPublic"
                      class="icon-arrow"
                      aria-hidden="true"
                    >
                      <use xlink:href="#icongongzhonghao"></use>
                    </svg>
                  </div>
                  <div class="friend-name-phone">{{ item.phone }}</div>
                </div>
              </div>
              <div class="time">
                最近访问：{{ item.latestVisitTime | formatterTime }}
              </div>
            </div>
            <svg class="icon-arrow" aria-hidden="true">
              <use xlink:href="#icona-1212jiantou"></use>
            </svg>
          </div>
        </van-list>
        <wxb-no-data v-else />
      </van-pull-refresh>
    </div>
  </van-popup>
</template>

<script>
import { Dialog } from 'vant';
import dayjs from 'dayjs';
import clueDetail from '../../../api/clue';

export default {
  name: 'relateFriend',
  props: {
    showFriend: {
      type: Boolean,
      default: () => false,
    },
    clueId: {
      type: String,
      default: () => '',
    },
  },
  watch: {
    showFriend(val) {
      this.showDialog = val;
      if (val) {
        this.friendList = [];
        this.pageNation = {
          pageNo: 0,
          pageSize: 20,
          totalRecords: 0,
        };
        this.onLoad();
      }
    },
  },
  filters: {
    formatterTime(val) {
      if (!val) return;
      return dayjs(val).format('YYYY-MM-DD HH:mm');
    },
  },
  computed: {
    defaultImg() {
      return (
        'this.src="' + require('@/assets/images/default_head_icon.png') + '"'
      );
    },
  },
  data() {
    return {
      pageNation: {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      },
      loading: false,
      finished: false,
      refreshing: false,
      showDialog: false,
      friendList: [],
    };
  },
  methods: {
    cancal() {
      this.showDialog = false;
    },
    close() {
      this.$emit('update:showFriend', false);
    },
    prompt() {
      Dialog.alert({
        title: '说明',
        message:
          '当用户转发H5或小程序被其他人访问时，转发者和访问者建立；他们之间可能有好友关系，利于置业顾问找出购房链路中的关键人物',
        confirmButtonText: '我知道了',
        confirmButtonColor: '#3A74F2',
        className: 'follow-btn',
        closeOnClickOverlay: true,
      }).then(() => {
        // on close
      });
    },
    //下拉刷新
    onRefresh() {
      this.pageNation.pageNo = 0;
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.friendList = [];
        this.refreshing = false;
      }
      this.pageNation.pageNo += 1;
      this.getPath();
    },
    async getPath() {
      this.loading = false;
      let params = {
        pageNo: 1,
        pageSize: 20,
        clueId: this.clueId,
      };
      let { code, data } = await clueDetail.getFriends(params);
      if (code === '0') {
        this.friendList = this.friendList.concat(data.data);
        this.pageNation.totalRecords = data.totalRecords;
        if (this.friendList.length >= this.pageNation.totalRecords) {
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
    },

    goDetail(clueId) {
      if (clueId === this.clueId) {
        this.showDialog = false;
        return;
      }
      let jumpHost = `${window.location.protocol}//` + location.host;
      window.location.href = jumpHost + `/potential/#/clue-detail?clueId=${clueId}`;
    },
  },
};
</script>

<style lang="less" scoped>
.tag-popup {
  .tag-popup-title {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f6f6f6;

    .reset-title {
      font-size: 14px;
      font-weight: 400;
      color: @gray6;
      line-height: 20px;
    }

    .center-title {
      font-size: 15px;
      font-weight: bold;
      color: @gray3;
      line-height: 21px;
    }

    .icon-arrow {
      width: 24px;
      height: 24px;
    }
  }

  .tag-popup-content {
    padding: 0px 16px 40px 16px;
    min-height: 372px;
    max-height: 500px;
    overflow-y: auto;

    .friend-list {
      background-color: @gary-wxb-gray-4;
      margin-top: 12px;
      padding: 15px 12px 16px 16px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .friend-info {
        .friend-content {
          display: flex;
          align-items: center;

          img {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            border: 1px solid #ebeef5;
          }

          .friend-name-label {
            margin-left: 12px;
            .friend-name-info {
              display: flex;
              align-items: center;
              span {
                font-size: 15px;
                font-weight: bold;
                color: @gray3;
                line-height: 21px;
              }

              .icon-arrow {
                width: 16px;
                height: 16px;
                margin-left: 8px;
              }
            }
            .friend-name-phone {
              font-size: 12px;
              color: #606266;
              line-height: 17px;
            }
          }
        }

        .time {
          margin-top: 16px;
          font-size: 12px;
          font-weight: 400;
          color: @gray9;
          line-height: 17px;
        }
      }

      .icon-arrow {
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>
<style lang="less">
.follow-btn {
  .van-dialog__confirm {
    background: #f0f4fe;

    &:active {
      background: #f0f4fe;
    }
  }
}
</style>
